<template>
  <div>
    <div class="mass-action-settings">
      <el-select
        v-model="actionData.role_name"
        style="width:350px"
        placeholder="Выберите роль"
        size="small"
      >
        <el-option
          v-for="role in roles"
          :key="role.name"
          :label="role.description"
          :value="role.name"
        >
        </el-option>
      </el-select>

      <div style="margin-top: 15px; margin-bottom: 10px">
      </div>


      <el-popconfirm
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите назначить роль?"
        @confirm="doAction"
      >
        <el-button
          slot="reference"
          size="small"
          type="primary"
          :disabled="actionButtonDisabled"
        >
          Запустить
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import AddUsersDialog from "@/components/user/AddUsersDialog.vue";

export default {
  name: "user-assign-role-action",
  mixins: [ActionTemplateMixin],
  components: {MassActionResult},

  computed: {
    actionButtonDisabled() {
      return !this.actionData.role_name;
    }
  },

  mounted() {
    this.getRolesInSystem();
  },
  data() {
    return {
      loadingRoles: false,
      roles: [],
      actionData: {
        role_name: null,
      }
    }
  },
  methods: {
    getRolesInSystem() {
      this.roles = [];

      this.loadingRoles = true;
      this.$api.rbac.getRolesInSystem()
        .then(data => {
          this.roles = data.roles;
        })
        .finally(() => {
          this.loadingRoles = false;
        })
    },
  },
}
</script>
